import $ from 'jquery'
import Cookies from 'js-cookie'
import data from './data.yaml'
//console.log(data)

function main() {
    $(document).ready(function () {
        //- setupTracking()
        updateCourseSelector()
        setFormTitle()
        monitorFormChanges()
        handleFormSubmission('#application-form')
        handleFormSubmission('#newsletter-subscription-form')
        handleFormSubmission('#fileupload-form')
        previewBetaFeatures()
    })
}

function setupTracking() {
    if (Cookies.get('trackingOptOut') == 'true') {
        console.log('The user has opted out, do nothing')
    } else {
        console.log('The user has not opted out, load analytics')
        loadAnalytics()
        console.log('Checking if GDPR should be shown')
        if (Cookies.get('trackingOptOut') == 'dismiss') {
            console.log('The user has dismissed the GDPR notice, do not show it')
        } else {
            console.log('Show GDPR notice')
            $('.gdpr-notice').removeClass('hidden')
        }
    }
    $('.tracking-notice-close-btn').click(function (e) {
        console.log('closing GDPR notice')
        e.preventDefault()
        document.cookie = 'trackingOptOut=dismiss'
        hideTrackingNotice()
    })
    $('.tracking-optout-btn').click(function (e) {
        console.log('opted out of tracking')
        e.preventDefault()
        Cookies.set('trackingOptOut', 'true', { path: '/', expires: 366 })
        hideTrackingNotice()
    })
}

function loadjs(file, callback) {
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = file
    script.onload = function () {
        if (callback) callback()
        console.debug('loaded', file)
    }
    document.body.appendChild(script)
}

function loadAnalytics() {
    var scriptUrl = 'https://www.googletagmanager.com/gtag/js?id=UA-175642162-2'
    loadjs(scriptUrl, function () {
        window.dataLayer = window.dataLayer || []
        function gtag() {
            dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', 'UA-175642162-2', { anonymize_ip: true })
    })
}

function hideTrackingNotice() {
    // console.log('GDPR notice closed')
    $('.gdpr-notice').addClass('hidden')
}

function updateCourseSelector() {
    // set the course selector options
    const form = $('#application-form')
    const courseSelector = $('#course-selector')
    const heading = $('#application-form-h1')
    if (form.length !== 0 && courseSelector.length !== 0) {
        data.courses.forEach(function (course) {
            const title = course.name + ' (' + course.date + ')'
            const option = '<option value="' + course.slug + '">' + title + '</option>'
            courseSelector.append(option)
        })
    }
    // pre-select the course if it's in the URL
    const urlParams = new URLSearchParams(window.location.search)
    const courseParam = urlParams.get('course')
    // get the course object
    const course = data.courses.find((course) => course.slug === courseParam)
    if (course && course.registration_open) {
        // valid course in URL, pre-select it and update the heading
        courseSelector.val(courseParam)
        heading.text('Apply for ' + courseSelector.find(':selected').text())
        const courseId = $('#course-id')
        courseId.val(courseParam)
        // is it advanced? un-hide the advanced-only fields
        const level = course.level.toLowerCase()
        if (!level.includes('advanced')) {
            $('#attended-introductory-course').val('not-applicable')
            $('.advanced-only').addClass('hidden')
        } else {
            $('#attended-introductory-course option[value="not-applicable"]').remove()
        }
    } else {
        // no valid course in URL, redirect to course page if we're on the apply page
        if (window.location.href.includes('/apply.html')) {
            window.location.href = '/courses.html'
        }
    }
}

function handleFormSubmission(formId) {
    var frm = $(formId)
    frm.submit(function (e) {
        e.preventDefault()
        $(formId + ' .ajax-loader').show()
        $(formId + ' .hide-on-submit').hide()
        var formData = new FormData(frm[0])
        $.ajax({
            type: frm.attr('method'),
            url: frm.attr('action'),
            dataType: 'text', // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: formData,
            complete: function () {
                $(formId + ' .ajax-loader').hide()
            },
            success: function (retData) {
                //console.log('Submission was successful.')
                //console.log(retData)
                $(formId + ' .submit-success').show()
            },
            error: function (retData) {
                console.error('An error occurred when submitting the application.')
                console.log(retData)
                $(formId + ' .hide-on-submit').show()
                $(formId + ' .submit-error').show()
            },
        })
    })
}

function monitorFormChanges() {
    $('select').change(updateForm)
    $('input').change(updateForm)
    $('#file').change(checkUploadFile)
}

function updateForm() {
    function showEl(el) {
        $(el).removeClass('hidden')
        $(el).find('input').attr('required', 'required')
    }
    function hideEl(el) {
        $(el).addClass('hidden')
        $(el).find('input').attr('required', null).val('')
    }
    let rules = [
        {
            trigger: '#orgtype',
            value: 'Other',
            target: '#orgtype-other',
            tasks: { yes: [showEl], no: [hideEl] },
        },
        {
            trigger: '#earthobs-usage',
            value: 'Yes',
            target: '#how-earthobs-usage',
            tasks: { yes: [showEl], no: [hideEl] },
        },
        {
            trigger: '#copernicus-usage',
            value: 'Yes',
            target: '#how-copernicus-usage',
            tasks: { yes: [showEl], no: [hideEl] },
        },
        {
            trigger: '#use-course-knowledge',
            value: 'Yes',
            target: '#how-use-course-knowledge',
            tasks: { yes: [showEl], no: [hideEl] },
        },
        {
            trigger: '#heard-from',
            value: 'Other',
            target: '#heard-source',
            tasks: { yes: [showEl], no: [hideEl] },
        },
    ]
    rules.forEach(function (rule) {
        var tasks = $(rule.trigger).val() === rule.value ? rule.tasks.yes : rule.tasks.no
        tasks.forEach(function (task) {
            task(rule.target)
        })
    })
}

function checkUploadFile(e) {
    if (e.currentTarget.files.length > 0) {
        var file = e.currentTarget.files[0] // puts all files into an array
        var filesize = (file.size / 1024 / 1024).toFixed(4) // MB
        if (file.name != 'item' && typeof file.name != 'undefined') {
            if (file.type != 'application/pdf') {
                alert('Sorry! Only PDF files can be uploaded.')
                e.currentTarget.value = ''
            } else if (filesize > 20) {
                alert(
                    'Sorry! This file is too large (> 20 MB). Please choose a different file or share your LinkedIn profile URL instead.'
                )
                e.currentTarget.value = ''
            } else {
                // un-require LinkedIn URL field if CV is being uploaded
                document.querySelector('#linkedin-url').removeAttribute('required')
            }
        }
    } else {
        // re-require LinkedIn URL field if CV is not being uploaded
        document.querySelector('#linkedin-url').setAttribute('required', 'required')
    }
}

function setFormTitle() {
    const urlParams = new URLSearchParams(window.location.search)
    const forParam = urlParams.get('for')
    const formTitleElem = document.getElementById('form-title')
    if (formTitleElem && forParam) {
        formTitleElem.value = forParam
    } else {
        //console.debug('could not set form title', forParam, 'on', formTitleElem)
    }
}

function previewBetaFeatures() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('preview')) {
        $('.preview').removeClass('hidden')
    }
}

window.toggleApplyPopup = function (e) {
    console.log(e)
    const popupEl = document.querySelector('#apply-popup')
    popupEl.classList.toggle('hidden')
}

main()
